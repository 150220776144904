<template>
  <div class="home h100">
    <div class="topbar">
      <div class="navbar flex">
        <div class="logo">
          <img @click="$router.push({path: '/'})" v-if="getConfig.logo_url" :src="getConfig.logo_url" class="logo_img">
        </div>
        <span @click="to(nav.path)" :class="['nav_item', {on: $route.path == nav.path}]" v-for="(nav, idx) in navbar" :key="idx">{{nav.name}}</span>
      </div>
      <div class="login">
        <span class="login_text" @click="to('/login')">Log in</span>
      </div>
    </div>
    <div class="home_inner">
      <router-view />
    </div>
  </div>
</template>
<script>
import commonMixins from '@/mixins/commonMixins'
export default {
  name: 'Index',
  mixins: [commonMixins],
  data(){
    return {
      navbar: [
        {
          name: 'Home',
          path: '/home'
        },
        {
          name: 'Diy store',
          path: '/diy'
        },
        {
          name: 'Solutions',
          path: '/solutions'
        },
        {
          name: 'About us',
          path: '/aboutus'
        },
        {
          name: 'Contact us',
          path: '/contactus'
        }
      ]
    }
  },
  methods: {
    to(path){
      this.$router.push({path})
    }
  }
}
</script>
<style lang="stylus" scoped>
.topbar
    height 50px
    background #fff
    border-bottom 1px solid #eee 
    padding 0 10px
    display flex
    justify-content space-between
    .login_text
        line-height 50px
        cursor pointer
        display block
    .nav_item
        padding 0 20px
        cursor pointer
        line-height 50px
        display block
        font-size 15px
        &.on
           color #43af52
           border-bottom 2px solid #43af43
        &:hover
           color #8fb895
.logo_img
    width 70px
    height 49px
    cursor pointer
</style>